/*
	Template Name 	: Investment Finance Advisor css Template
	Author 			: Awaiken Theme
	Author Url 		: https://awaikenthemes.com
*/

/*  General css  */

body{
	font-size: 16px;
	font-family: 'Montserrat', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6{
	margin-top:0;
	font-weight: 700;
	font-family: 'Josefin Sans', sans-serif;
}

img{
	max-width: 100%;
}

a:hover{
	text-decoration: none;
	outline: 0;
}

a:focus{
	text-decoration: none;
	outline: 0;
}

::-moz-selection {
    color: #fff;
    background: #20bdd8;
}

::selection {
	color: #fff;
    background: #20bdd8;
}

.preloader{
    background: #0f2440;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999;
}

.browser-screen-loading-content {
    text-align: center;
    height: 2em;
    max-width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 3em;
    left: 0;
    margin: auto;
}

.loading-dots {
    margin-left: -1.5em;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-animation: loading-dots-fadein .5s linear forwards;
    -moz-animation: loading-dots-fadein .5s linear forwards;
    -o-animation: loading-dots-fadein .5s linear forwards;
    -ms-animation: loading-dots-fadein .5s linear forwards;
    animation: loading-dots-fadein .5s linear forwards;
}

.loading-dots i {
    width: .5em;
    height: .5em;
    display: inline-block;
    vertical-align: middle;
    background: #fff;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    margin: 0 .125em;
    -webkit-animation: loading-dots-middle-dots .5s linear infinite;
    -moz-animation: loading-dots-middle-dots .5s linear infinite;
    -o-animation: loading-dots-middle-dots .5s linear infinite;
    -ms-animation: loading-dots-middle-dots .5s linear infinite;
    animation: loading-dots-middle-dots .5s linear infinite;
}

.loading-dots.dark-gray i {
    background: #fff;
}

.loading-dots i:first-child {
    -webkit-animation: loading-dots-first-dot .5s infinite;
    -moz-animation: loading-dots-first-dot .5s linear infinite;
    -o-animation: loading-dots-first-dot .5s linear infinite;
    -ms-animation: loading-dots-first-dot .5s linear infinite;
    animation: loading-dots-first-dot .5s linear infinite;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translate(-1em);
    -moz-transform: translate(-1em);
    -o-transform: translate(-1em);
    -ms-transform: translate(-1em);
    transform: translate(-1em);
}

.loading-dots i:last-child {
    -webkit-animation: loading-dots-last-dot .5s linear infinite;
    -moz-animation: loading-dots-last-dot .5s linear infinite;
    -o-animation: loading-dots-last-dot .5s linear infinite;
    -ms-animation: loading-dots-last-dot .5s linear infinite;
    animation: loading-dots-last-dot .5s linear infinite;
}

.loading-text{
	color: #fff;
	margin-top: 20px;
	letter-spacing: 0.1em;
}

@-moz-keyframes loading-dots-fadein {
    100% {
        opacity: 1;
        -ms-filter: none;
        filter: none
    }
}

@-webkit-keyframes loading-dots-fadein {
    100% {
        opacity: 1;
        -ms-filter: none;
        filter: none
    }
}

@-o-keyframes loading-dots-fadein {
    100% {
        opacity: 1;
        -ms-filter: none;
        filter: none
    }
}

@keyframes loading-dots-fadein {
    100% {
        opacity: 1;
        -ms-filter: none;
        filter: none
    }
}

@-moz-keyframes loading-dots-first-dot {
    100% {
        -webkit-transform: translate(1em);
        -moz-transform: translate(1em);
        -o-transform: translate(1em);
        -ms-transform: translate(1em);
        transform: translate(1em);
        opacity: 1;
        -ms-filter: none;
        filter: none
    }
}

@-webkit-keyframes loading-dots-first-dot {
    100% {
        -webkit-transform: translate(1em);
        -moz-transform: translate(1em);
        -o-transform: translate(1em);
        -ms-transform: translate(1em);
        transform: translate(1em);
        opacity: 1;
        -ms-filter: none;
        filter: none
    }
}

@-o-keyframes loading-dots-first-dot {
    100% {
        -webkit-transform: translate(1em);
        -moz-transform: translate(1em);
        -o-transform: translate(1em);
        -ms-transform: translate(1em);
        transform: translate(1em);
        opacity: 1;
        -ms-filter: none;
        filter: none
    }
}

@keyframes loading-dots-first-dot {
    100% {
        -webkit-transform: translate(1em);
        -moz-transform: translate(1em);
        -o-transform: translate(1em);
        -ms-transform: translate(1em);
        transform: translate(1em);
        opacity: 1;
        -ms-filter: none;
        filter: none
    }
}

@-moz-keyframes loading-dots-middle-dots {
    100% {
        -webkit-transform: translate(1em);
        -moz-transform: translate(1em);
        -o-transform: translate(1em);
        -ms-transform: translate(1em);
        transform: translate(1em)
    }
}

@-webkit-keyframes loading-dots-middle-dots {
    100% {
        -webkit-transform: translate(1em);
        -moz-transform: translate(1em);
        -o-transform: translate(1em);
        -ms-transform: translate(1em);
        transform: translate(1em)
    }
}

@-o-keyframes loading-dots-middle-dots {
    100% {
        -webkit-transform: translate(1em);
        -moz-transform: translate(1em);
        -o-transform: translate(1em);
        -ms-transform: translate(1em);
        transform: translate(1em)
    }
}

@keyframes loading-dots-middle-dots {
    100% {
        -webkit-transform: translate(1em);
        -moz-transform: translate(1em);
        -o-transform: translate(1em);
        -ms-transform: translate(1em);
        transform: translate(1em)
    }
}

@-moz-keyframes loading-dots-last-dot {
    100% {
        -webkit-transform: translate(2em);
        -moz-transform: translate(2em);
        -o-transform: translate(2em);
        -ms-transform: translate(2em);
        transform: translate(2em);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0)
    }
}

@-webkit-keyframes loading-dots-last-dot {
    100% {
        -webkit-transform: translate(2em);
        -moz-transform: translate(2em);
        -o-transform: translate(2em);
        -ms-transform: translate(2em);
        transform: translate(2em);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0)
    }
}

@-o-keyframes loading-dots-last-dot {
    100% {
        -webkit-transform: translate(2em);
        -moz-transform: translate(2em);
        -o-transform: translate(2em);
        -ms-transform: translate(2em);
        transform: translate(2em);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0)
    }
}

@keyframes loading-dots-last-dot {
    100% {
        -webkit-transform: translate(2em);
        -moz-transform: translate(2em);
        -o-transform: translate(2em);
        -ms-transform: translate(2em);
        transform: translate(2em);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0)
    }
}

/*  Navigation area css  */

.navbar{
	margin-bottom: 0;
}

.navbar-nav{
	padding: 20px 0;
}

.navbar-brand{
	padding: 0 15px;
}

.navbar-brand img{
	height: 73px;
	transition: all 0.3s;
}

.navbar-brand h1{
	font-size: 30px;
	margin: 0;
	color: #8bc34a;
	font-family: 'Berkshire Swash', cursive;
	font-weight: normal;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
}

.navbar-brand h1 span{
	color: #fff;
	font-family: 'Saira Semi Condensed', sans-serif;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
}

.navbar-default{
	background: #0f2440;
	border: none;
	border-bottom: none;
	padding: 20px 0;
	border-radius: 0;
	z-index: 12;
	transition: all 1s;
	-webkit-transition: all 1s;
	-moz-transition: all 1s;
}

.navbar-default .navbar-nav>li{
	margin: 0 2px;
}

.navbar-default .navbar-nav>li>a:hover{
	background: #20bdd8;
	color: #0f2440;
}

.navbar-default .navbar-nav>li>a:focus{
	color: #fff;
}

.sticky-header{
	background: #0f2440;
	padding: 10px 0;
	border-bottom: none;
	transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
}

.sticky-header .navbar-nav{
	padding: 10px 0;
}

.sticky-header .navbar-brand h1 i,
.sticky-header .navbar-brand h1{
	color: #24292f;
}

.navbar-default .navbar-nav>li>a{
	font-size: 14px;
	text-transform: uppercase;
	color: #fff;
	letter-spacing: 0.04em;
	padding: 6px 15px; 
	position: relative;
	border-radius: 4px;
}

.active a{
	background: #20bdd8 !important;
	color: #0f2440 !important;
}

.sticky-header .navbar-brand img{
	height: 50px;
}

.navbar-default .active a:after{
	transform: scale(1) !important;
	-webkit-transform: scale(1) !important;
	-moz-transform: scale(1) !important;
}

.btn-custom{
	display: inline-block;
	min-width: 180px;
	text-align: center;
	background: #8bc34a;
	color: #fff;
	text-transform: uppercase;
	font-size: 14px;
	border-radius: 4px;
	padding: 14px 20px;
	font-weight: 600;
	letter-spacing: 0.04em;
	transition: all 0.4s;
}

.btn-custom:hover{
	background: #212121;
	color: #fff;
}

.btn-custom:focus{
	color: #fff;
}

.navbar-default .navbar-toggle{
	border-radius: 0;
	border-color: #fff;
	padding: 0;
}

.navbar-default .navbar-toggle .icon-bar{
	background: #fff;
}

.navbar-default .navbar-toggle:hover{
	background: none;
}

/*  Banner area css  */

.row.no-gutters {
	margin: 0;
}

.row.no-gutters > [class*='col-'] {
	padding: 0;
}

.banner{
	position: relative;
	top: 0px;
	left: 0;
	width: 100%;
	background: url(../images/banner.jpg) no-repeat top center fixed;
	background-size: cover;
	padding: 200px 0 100px;
}

.banner .row{
	position: relative;
	z-index: 1;
}

.about-company{
	background: #0f2440;
}

.about-description{
	padding: 40px 30px;
}

.about-description h2{
	color: #fff;
	text-transform: uppercase;
	font-weight: 400;
	margin-bottom: 1em;
}

.about-description p{
	color: #fff;
	font-size: 14px;
	line-height: 1.8em;
	font-weight: 300;
}

.btn-video{
	display: inline-block;
	color: #20bdd8;
	margin-top: 20px;
	transition: all 0.3s;
}

.btn-video:hover,
.btn-video:focus{
	color: #fff;
}

.btn-video i{
	font-size: 40px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}

/*  Services area css  */

.services{
	padding: 100px 0 140px;
}

.main-title{
	text-align: center;
}

.main-title h2{
	text-transform: uppercase;
	color: #0f2440;
	font-size: 34px;
	line-height: 1.4em;
	margin-bottom: 80px;
}

.service-single{
	text-align: center;
}

.service-single .icon-box{
	font-size: 80px;
	color: #20bdd8;
}

.service-single h3{
	position: relative;
	text-transform: uppercase;
	color: #0f2440;
	font-size: 16px;
	line-height: 1.5em;
	margin: 1.5em 0;
	padding-bottom: 14px;
	min-height: 62px;
}

.service-single h3:after{
	content: '';
	display: block;
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 30px;
	margin-left: -15px;
	height: 1px;
	background: #20bdd8;
}

.service-single p{
	color: #5a5c5e;
	font-size: 14px;
	line-height: 1.5em;
}

/*  Features area css  */

.features{
	background: linear-gradient(left,#efeded 0, #efeded 50%, #0f2440 50%);
	background: -webkit-linear-gradient(left,#efeded 0, #efeded 50%, #0f2440 50%);
	background: -moz-linear-gradient(left,#efeded 0, #efeded 50%, #0f2440 50%);
	background: -o-linear-gradient(left,#efeded 0, #efeded 50%, #0f2440 50%);
}

.feature-image img{
	margin-top: -60px;
}

.feature-description{
	padding: 10% 0 10% 10%;
}

.feature-description h2{
	text-transform: uppercase;
	color: #fff;
	font-size: 34px;
	line-height: 1.4em;
	margin-bottom: 80px;
	font-weight: 600;
}

.feature-single{
	position: relative;
	padding-left: 100px;
	margin-bottom: 60px;
}

.feature-single .icon-box{
	position: absolute;
	top: 0;
	left: 0;
	font-size: 70px;
	color: #20bdd8;
	line-height: 1em;
}

.feature-single h3{
	color: #fff;
	font-size: 20px;
	font-weight: 400;
	font-family: 'Montserrat', sans-serif;
	margin-bottom: 16px;
}

.feature-single p{
	color: #a2a2a2;
	font-size: 14px;
	font-weight: 300;
}

.btn-contact{
	display: inline-block;
	background: #20bdd8;
	color: #fff;
	font-size: 18px;
	letter-spacing: 0.18em;
	text-transform: uppercase;
	padding: 20px 30px 18px;
	transition: all 0.3s;
}

.btn-contact:hover{
	background: #0e9fb8;
	color: #fff;
}

/*  Our Team area css  */

.ourteam{
	padding: 100px 0;
}

.team-single{
	text-align: center;
}

.team-image{
	position: relative;
	background: #0f2440;
}

.team-image img{
	width: 100%;
	opacity: 1;
	transition: all 0.3s;
}

.team-image:before{
	content: '';
	display: block;
	position: absolute;
	top: 20px;
	left: 20px;
	right: 20px;
	bottom: 20px;
	border-top: 1px solid #fff;
	border-bottom: 1px solid #fff;
	transform: scale(0,1);
	transition: all 0.3s;
	z-index: 1;
}

.team-image:after{
	content: '';
	display: block;
	position: absolute;
	top: 20px;
	left: 20px;
	right: 20px;
	bottom: 20px;
	border-left: 1px solid #fff;
	border-right: 1px solid #fff;
	transform: scale(1,0);
	transition: all 0.3s;
	z-index: 1;
}

.team-single:hover .team-image:before,
.team-single:hover .team-image:after{
	transform: scale(1);
}

.team-single:hover .team-image img{
	opacity: 0.8;
}

.team-info{
	background: #0f2440;
	padding: 30px 10px;
}

.team-info h3{
	color: #fff;
	text-transform: uppercase;
	font-size: 20px;
	font-weight: 500;
	font-family: 'Montserrat', sans-serif;
	margin-bottom: 8px;
}

.team-info p{
	font-size: 14px;
	color: #fff;
	font-weight: 300;
}

.team-social{
	position: relative;
	padding-top: 20px;
	margin-top: 20px;
}

.team-social:before{
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -30px;
	width: 60px;
	height: 1px;
	background: #fff;
}

.team-social a{
	display: inline-block;
	color: #fff;
	font-size: 14px;
	padding: 0 4px;
}

/*  Testimonial area css  */

.testimonial{
	padding: 100px 0;
	background: #efeded;
}

.testimonial-single{
	background: #fff;
}

.testimonial-single:after{
	content: '';
	display: block;
	clear: both;
}

.testimonial-image{
	width: 50%;
	float: left;
}

.testimonial-image img{
	width: auto;
}

.testimonial-info{
	width: 50%;
	float: right;
	padding: 20px;
}

.testimonial-info h3{
	color: #20bdd8;
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 1.5em;
}

.testimonial-info p{
	font-size: 14px;
	color: #858284;
	font-weight: 300;
	line-height: 1.5em;
}

.testimonial-slider .owl-nav{
	
}

.testimonial-slider .owl-nav .owl-next,
.testimonial-slider .owl-nav .owl-prev{
	display: inline-block;
	width: 40px;
	height: 40px;
	border: 2px solid #0f2440;
	border-radius: 50%;
	text-align: center;
	padding-top: 8px;
	color: #0f2440;
	position: absolute;
	top: 50%;
	margin-top: -20px;
}

.testimonial-slider .owl-nav .owl-next{
	right: -70px; 
}

.testimonial-slider .owl-nav .owl-prev{
	left: -70px;
}

.testimonial-slider .owl-nav .owl-next:hover,
.testimonial-slider .owl-nav .owl-prev:hover{
	background: #0f2440;
	color: #fff;
}

/*  Our Project area css  */

.ourproject{
	padding: 100px 0;
}

.project-single{
	position: relative;
	margin-bottom: 30px;	
}

.project-overlay{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0,0,0,0.33);
	padding: 30px;
}

.project-overlay h3{
	color: #fff;
	font-size: 18px;
	font-weight: 500;
	font-family: 'Montserrat', sans-serif;
}

.project-overlay p{
	color: #fff;
	font-size: 14px;
	font-weight: 300;
	margin-bottom: 8px;
}

.completed-project{
	position: relative;
	background: url("../images/completed-bg.jpg") no-repeat center center;
	background-size: cover;
	padding: 100px 0 80px;
}

.completed-project:before{
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(15,36,64,0.84);
}

.completed-title{
	margin-bottom: 60px;
	text-align: center;
}

.completed-title h2{
	font-size: 34px;
	color: #fff;
	text-transform: uppercase;
}

.completed-single{
	text-align: center;
}

.completed-single h3{
	color: #fff;
	font-size: 50px;
	font-family: 'Montserrat', sans-serif;
	margin-bottom: 0;
}

.completed-single p{
	color: #fff;
	font-size: 20px;
}

/*  Request Contact Form area css  */

.request-callback{
	padding: 100px 0;
}

.request-form .form-group{
	margin-bottom: 30px;
}

.request-form .form-control{
	border: none;
	box-shadow: none;
	background: #e7e4e4;
	border-radius: 0;
	height: auto;
	padding: 12px 20px;
}

.btn-request{
	display: block;
	background: #0f2440;	
	color: #fff;
	border: none;
	width: 100%;
	text-transform: uppercase;
	font-weight: 300;
	padding: 12px;
	font-size: 14px;
	letter-spacing: 0.1em;
	transition: all 0.3s;
}

.btn-request:hover{
	background: #000;
}

.contact-information{
	background: #223a5a;
	padding: 30px 0;
}

.contact-single{
	text-align: center;
}

.contact-single p{
	color: #fff;
	font-size: 20px;
	font-weight: 300;
	margin-bottom: 0;
}

.contact-single i{
	margin-right: 10px;
}

/*  Footer area css  */

footer{
	background: #0f2440;
	padding: 80px 0 40px;
}

.footer-about img{
	margin-bottom: 30px;
}

.footer-about p{
	color: #fff;
	font-size: 14px;
	font-weight: 300;
	line-height: 1.7em;
}

.footer-link h2{
	color: #fff;
	font-size: 20px;
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: 56px;
	position: relative;
	padding-bottom: 20px;
}

.footer-link h2:after{
	content: '';
	display: block;
	width: 200px;
	height: 1px;
	position: absolute;
	bottom: 0;
	background: #fff;
}

.footer-link ul{
	padding: 0;
	margin: 0;
}

.footer-link ul li{
	width: 50%;
	display: inline-block;
	margin-right: -5px;
	padding: 2px 0;
}

.footer-link ul li a{
	color: #fff;
	font-size: 14px;
	font-weight: 300;
}

.search-query{
	padding: 16px;
	height: auto;
	margin-bottom: 0;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	background: none;
	color: #fff;
	border-radius: 0;
	border: 1px solid #20bdd8;
}

.search-query:focus{
	box-shadow: none;
	outline: 0;
}

.newsletter button {
	border: 0;
	height: 54px;
	width: 54px;
	background: #20bdd8;
	position: relative;
	left: 0px;
	margin-bottom: 0;
	border-radius: 0;
	color:#fff;
	font-size: 22px;
}

.search-query:focus + button {
	z-index: 3;
}

.newsletter p{
	color: #fff;
	font-size: 14px;
	font-weight: 300;
	margin-bottom: 20px;
}

.footer-social{
	margin-top: 30px;
}

.footer-social a{
	display: inline-block;
	width: 50px;
	height: 50px;
	background: #fff;
	text-align: center;
	font-size: 20px;
	color: #0f2440;
	border-radius: 50%;
	padding-top: 12px;
	margin-right: 6px;
	transition: all 0.3s;
}

.footer-social a:hover{
	background: #20bdd8;
	color: #fff;
}

.siteinfo{
	text-align: center;
	margin-top: 80px;
}

.siteinfo p{
	color: #fff;
	font-weight: 300;
}

.siteinfo a{
	color: #fff;
}

.siteinfo a:hover{
	color: #20bdd8;
}

/*  Media Query area css  */

@media only screen and (max-width: 1300px) {
	.testimonial-slider{
		padding: 0 60px;
	}
	
	.testimonial-slider .owl-nav .owl-next{
		right: 0;
	}
	
	.testimonial-slider .owl-nav .owl-prev{
		left: 0;
	}
}

@media only screen and (max-width: 1200px) {
	.testimonial-image{
		float: none;
		width: 100%;
	}
	
	.testimonial-info{
		width: 100%;
		float: none;
	}
}

@media only screen and (max-width: 991px) {
	.navbar-default .navbar-nav>li>a{
		font-size: 12px;
		padding: 6px 4px;
	}
	
	.features{
		background: #efeded;
	}
	
	.feature-description{
		background: #0f2440;
		padding: 40px;
	}
	
	.feature-image{
		text-align: center;
	}
	
	.row.no-gutters-responsive{
		margin: 0;
	}

	.row.no-gutters-responsive > [class*='col-'] {
		padding: 0;
	}
	
	.features .container{
		padding: 0;
	}
	
	.team-single{
		margin-bottom: 30px;
	}
	
	.project-single{
		max-width: 480px;
		margin: 0 auto 30px;
	}
	
	.completed-single{
		margin-bottom: 30px;
	}
	
	.contact-information{
		padding-bottom: 10px;
	}
	
	.contact-single{
		margin-bottom: 20px;
	}
	
	.footer-about,
	.footer-link{
		margin-bottom: 40px;
	}
	
	.footer-link h2{
		margin-bottom: 30px;
	}
}

/* Mobile Layout */
@media only screen and (max-width: 767px){
	
	#responsive-menu{
		display: block;
		
	}
	
	.navbar-brand img{
		height: 50px;
	}
	
	.slicknav_menu{
		margin-left: -15px;
		margin-right: -15px;
		padding: 0;
		background: none;
	}

	.slicknav_menu ul{
		background: #0f2440;
		position: relative;
		top: 16px;
	}
	
	.slicknav_menu li{
		padding: 0 10px;
	}

	.slicknav_menu .active a{
		color: #24292f;
	}
	
	.slicknav_menu a{
		color: #fff;
		padding-top: 6px;
		padding-bottom: 6px;
		text-transform: uppercase;
		border: 1px solid transparent;
	}

	.slicknav_menu a a{
		border: none !important;
	}
	
	.slicknav_nav .slicknav_row:hover,
	.slicknav_nav a:hover{
		color: #fff;
	}
	
	.slicknav_menu  .active a{
		color: #24292f !important;
	}
	
	.slicknav_btn{
		padding: 4px 4px;
		background: none;
	}
	
	.slicknav_icon-bar{
		box-shadow: none;
		border-radius: 0;
		margin: 2px 0;
	}
	
	.banner{
		padding: 140px 0 40px;
	}
	
	.main-title h2{
		font-size: 22px;
		margin-bottom: 60px;
	}
	
	.services{
		padding: 60px 0 100px;
	}
	
	.ourteam,
	.testimonial,
	.ourproject,
	.request-callback{
		padding: 60px 0;
	}
	
	.completed-project{
		padding: 60px 0 40px;
	}
	
	.feature-description h2{
		margin-bottom: 30px;
		font-size: 24px;
	}
	
	.feature-single{
		margin-bottom: 40px;
	}
	
	.service-single{
		margin-bottom: 30px;
	}
	
	.team-single{
		max-width: 320px;
		margin: 0 auto 30px;
	}
	
	.completed-title h2{
		font-size: 22px;
	}
	
}

/* Small Mobile Layout */
@media only screen and (max-width: 567px){
	.testimonial-slider{
		padding: 0;
	}
	
	.testimonial-slider .owl-nav{
		text-align: center;
		padding-top: 20px;
	}
	
	.testimonial-slider .owl-nav .owl-next,
	.testimonial-slider .owl-nav .owl-prev{
		position: relative;
		margin: 0 4px;
	}
}

@media only screen and (max-width: 480px){
	.about-description h2{
		font-size: 24px;
	}

	.about-description h2 br{
		display: none;
	}
	
	.feature-description{
		padding: 40px 20px;
	}
	
	.feature-single{
		padding-left: 0;
	}
	
	.feature-single .icon-box{
		position: relative;
	}
	
	.siteinfo{
		margin-top: 40px;
	}
}