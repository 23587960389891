/*
  Flaticon icon font: Flaticon
  Creation date: 27/09/2017 12:49
  */

@font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.eot");
    src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Flaticon.woff") format("woff"),
    url("../fonts/Flaticon.ttf") format("truetype"),
    url("../fonts/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: "Flaticon";
        src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
    }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-style: normal;
}

.flaticon-right-arrow:before { content: "\f100"; }
.flaticon-support:before { content: "\f101"; }
.flaticon-telemarketer:before { content: "\f102"; }
.flaticon-team:before { content: "\f103"; }
.flaticon-rocking-chair:before { content: "\f104"; }
.flaticon-money:before { content: "\f105"; }
.flaticon-line-chart:before { content: "\f106"; }
.flaticon-play-button:before { content: "\f107"; }